<template>
  <div>
    <div class="row">
      <!-- <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start mb-5">
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
              @click="Filter('thisWeek')"
            >
              Minggu Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
              @click="Filter('thisMonth')"
            >
              Bulan Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
              @click="Filter('lastMonth')"
            >
              Bulan Kemarin
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
              @click="Filter('thisYear')"
            >
              Tahun Ini
            </button>
          </div>
        </div>
      </div> -->

      <div class="col-md-12">
          <span>*Rata-rata diambil berdasarkan data perjanjian 2 bulan terakhir</span>
      </div>

      <div class="col-md-12">
        <div class="row" v-if="items != []">
            <div class="col-md-2 mb-2" v-for="(item,index) in items" :key="index">
                
                <div class="card" style="min-height:180px">
                  <!-- <img class="card-img-top" src="https://api.klinik-hayandra.com/storage/users/dc90d41e-d54d-43dd-9497-8c383b701870_PP WA6 3.jpg" alt="photo"> -->
                  <img class="card-img-top" :src="item.photo" alt="photo">
                  <div class="card-body p-4">

                      <div class="row">
                          <div class="col-md-12">
                            <span class="d-block font-weight-bold" style="font-size:12px">{{item.name}}</span>
                            <span class="d-block" style="font-size:11px">{{item.polyclinic_name}}</span>
                          </div>

                          <div class="col-md-12 ">
                            <span class="font-weight-bold d-block" style="font-size:12px">rata-rata :</span>
                            
                            <div class="ml-4">
                                <span class="d-block" style="font-size:12px">Perjanjian : {{item.avgData.avgAppointment}}</span>
                                <span class="d-block" style="font-size:12px">Pasien : {{item.avgData.avgPatient}}</span>
                                <span class="d-block" style="font-size:12px">Tindakan : {{item.avgData.avgMedicalAction}}</span>
                            </div>
                            
                          </div>
                          
                      </div>
                  </div>
                </div>

                <!-- <b-card
                :img-src="item.photo"
                img-alt="Image"
                img-top
                style="max-width: 20rem;"
                class="mb-1 p-1"
                no-body
                >
                <b-card-body>
                    <span class="d-block font-weight-bold" style="font-size:12px">{{item.name}}</span>
                    <span class="d-block" style="font-size:11px">{{item.polyclinic_name}}</span>
                </b-card-body>
                <b-card-text>
                    <span class="d-block font-weight-bold" style="font-size:12px">{{item.name}}</span>
                    <span class="d-block" style="font-size:11px">{{item.polyclinic_name}}</span>
                </b-card-text>

                </b-card> -->
            </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },
      tooltipPeriod: "thisWeek",

      // data
      items:[]
    };
  },
  methods: {
    Filter(tool) {
      this.tooltipPeriod = tool;
      this.chartLoad = false;
      if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
      }
      this.getData();

      mapGetters(["layoutConfig"]);
    },

    async getData() {
      // let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      // let response = await module.get("active-doctor", `${filterParams}`);
      let response = await module.get("active-doctor");

      this.items = response

    },
  },

  mounted() {
    this.getData();
  },
};
</script>

